import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { withRouter } from 'react-router-dom'
import { Switch } from '@mui/material'

import {
  get_cipher_other_hostname,
  should_enable_switch
} from '../../utils/switch_cipher.js'
import { get_static_classname, track_user_event } from '../../utils/tracking_utils.js'

import cs from '../cipher_styles.module.scss'

const CipherSwitch = ({location}) => {

  const [show_other_cipher, set_show_other_cipher] = useState(false)

  useEffect(() => {
    if (show_other_cipher) {
      const {pathname, search} = location
      const url = `${get_cipher_other_hostname()}${pathname}${search ? search : ''}`
      window.location.replace(url)
    }
  }, [show_other_cipher, location])

  function switch_to_other_cipher() {
    track_user_event('action="switch_cipher" target="new_design"')

    set_show_other_cipher(true)
  }

  const is_enabled = should_enable_switch()

  if (!is_enabled) return null

  return (
    <div className={cn('d-flex flex-nowrap ml-2', get_static_classname('switch_to_new'), cs.cursor_pointer )} onClick={switch_to_other_cipher} title='Switch to new design'>
      <span className={cn('my-auto', cs.cipher_white_text)}>
        New Design
      </span>
      <Switch
        sx={{
          '.MuiSwitch-track' : {
            backgroundColor: '#999999 !important',
            opacity: '1 !important'
          }
        }}
        className='my-auto'
        checked={false}
      />
    </div>
  )
}

export default withRouter(CipherSwitch)
