export const CIPHER_AWS_HOSTNAME = 'app.aws.cipher.ai'
export const CIPHER31_PREV_HOSTNAME = 'app31.cipher.ai'
export const BM_PREV_HOSTNAME = 'bm.cipher.ai'

export const CIPHER_HOSTNAME = 'https://app.cipher.ai'

const CIPHER_NEW_HOSTNAME = 'app.cipher.ai'

const CIPHER_OTHER_HOSTNAME = 'https://' + CIPHER_NEW_HOSTNAME

export function get_cipher_other_hostname() {
  return CIPHER_OTHER_HOSTNAME
}

export function should_enable_switch() {
  return true
}
